import { history } from "../managers/history";
import swal from "sweetalert";
import ShowToast from "../common/components/showToast";
import { connectionsMap, toastType } from "../constants";

const moment = require("moment");

const utility = {
  getHeader,
  apiFailureToast,
  apiSuccessToast,
  generateGUID,
  basicAlert,
  validationAlert,
  isNumber,
  emailShortner,
  navigateToPath,
  toggleDropDown,
  validateName,
  validateEmail,
  isEmpty,
  isMenuActive,
  isPasswordValid,
  showUnderDevelopment,
  epochToDate,
  dateCompare,
  tokenShorten,
  getTimeFromNow,
  epocToPrettyTime,
  getTimeDifference,
  parseResponse,
  storeToLocalStorage,
  getDataFromLocalStorage,
  addDays,
  generateScript,
  capitalizeFirstLetter,
  formatDate,
  formatSessionTime,
  shorten,
  objectsAreEqual,
  emailShorten,
  formatDateTime,
  convertDecimal,
  createWidgetScript,
  companyNameShortner,
  epocToPrettyDateTime,
  formatCreditsDecimal,
  numberWithCommas,
  isUnlimited,
  convertEpochToDateTime,
  formatDateWithAmPm,
  convertSecondsToReadableTime,
  formatDateHoursMinutes,
  capitalizeWords,
  truncateText,
  epocToPrettyTimeV2,
  getStatusAttributes,
  formatNumber,
  getFileNameWithoutExtension,
  convertToMarkup,
  getConnectionIcons,
  formatDuration,
  getFileType,
  initializeStateWithDefaults,
};
export default utility;

export const dispatchAction = (type, data) => {
  return (dispatch) => dispatch({ type, data });
};

function getHeader() {}

function storeToLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

function getDataFromLocalStorage(key) {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
}
function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function parseResponse(promise) {
  return promise
    .then((data) => {
      return [null, data];
    })
    .catch((err) => [err]);
}

function apiFailureToast(message) {
  ShowToast({
    message: message ? message : "apiConstant.API_FAILURE",
    type: toastType.ERROR,
  });
}

function apiSuccessToast(msg) {
  ShowToast({
    message: msg ? msg : "apiConstant.API_SUCCESS",
    type: toastType.SUCCESS,
  });
}

function generateGUID() {
  const nav = window.navigator;
  const screen = window.screen;
  let guid = String(nav.mimeTypes.length);
  guid += `${nav.userAgent.replace(/\D+/g, "")}`;
  guid += `${nav.plugins.length}`;
  guid += `${screen.height || ""}`;
  guid += `${screen.width || ""}`;
  guid += `${screen.pixelDepth || ""}`;

  return guid;
}

function basicAlert(message) {
  swal({
    title: message,
    icon: "/images/alert-icon.png",
  });
}

function validationAlert(message, type = "info") {
  swal({
    title: message,
    icon: type,
  });
}

function getTimeDifference(timeStampTo) {
  let minFive = 300000;
  let oneDay = 86400000;
  let difference = "";
  let am = " AM";
  let pm = " PM";
  let hh = epochToDate(timeStampTo, "hh");
  let mm = epochToDate(timeStampTo, "mm");
  let dateFormat = epochToDate(timeStampTo, "DD MMM YYYY");
  let hours = new Date(timeStampTo).getHours();
  let timeDifference = new Date().getTime() - timeStampTo;
  if (timeDifference < oneDay) {
    if (timeDifference < minFive) {
      difference = "Just Now";
    } else {
      if (hours < 12) difference = "Today at " + hh + ":" + mm + am;
      else difference = "Today at " + hh + ":" + mm + pm;
    }
  } else {
    if (hours < 12) difference = dateFormat + ", " + hh + ":" + mm + am;
    else difference = dateFormat + ", " + hh + ":" + mm + pm;
  }
  return difference;
}

function epochToDate(timeStamp, timeFormat) {
  timeStamp = Math.floor(timeStamp); //to convert to integer if seconds is String.
  let dateObject = new Date(timeStamp);
  return moment(dateObject).format(timeFormat); //DD MMM YYYY
}

function getTimeFromNow(timeStamp) {
  return moment(timeStamp, "YYYYMMDD").fromNow();
}

function dateCompare(timeStampFrom, timeStampTo) {
  return (timeStampFrom * 1000 - timeStampTo) / (1000 * 60 * 60 * 24);
}

function addDays(date, days) {
  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
}

function navigateToPath(path) {
  history.push(path);
}

function toggleDropDown(dropdownID) {}

function validateName(name) {
  let reg = /[A-Z][a-zA-Z]*/;
  return reg.test(name);
}

function validateEmail(email) {
  let reg =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

function isPasswordValid(password) {
  // eslint-disable-next-line
  let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return reg.test(password);
}

function isEmpty(string) {
  return !string || string.trim().length === 0;
}

function isMenuActive(path) {
  return window.location.pathname.includes(path);
}

function showUnderDevelopment() {
  basicAlert("Under Development");
}

function capitalizeFirstLetter(str) {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function epocToPrettyTime(seconds) {
  seconds = Math.floor(seconds);
  const nowMilliseconds = new Date().getTime();
  const nowSeconds = Math.floor(nowMilliseconds / 1000);
  const inputTime = new Date(seconds);
  const inputSeconds = Math.floor(inputTime.getTime() / 1000);

  const elapsedSeconds = nowSeconds - inputSeconds;

  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0);
  const todayStartSeconds = Math.floor(todayStart.getTime() / 1000);

  if (elapsedSeconds < 60) {
    return "Just now";
  }

  const intervalMinutes = Math.floor(elapsedSeconds / 60);
  if (intervalMinutes < 60) {
    if (intervalMinutes === 1) return "1 min ago";
    return intervalMinutes + " min ago";
  }

  const intervalHours = Math.floor(elapsedSeconds / 3600);
  if (intervalHours < 24 && inputSeconds >= todayStartSeconds) {
    return "Today " + moment(inputTime).format("hh:mm A");
  }

  return moment(inputTime).format("DD MMM YYYY, h:mm A");
}



function epocToPrettyTimeV2(seconds) {
  seconds = Math.floor(seconds);
  const nowMilliseconds = new Date().getTime();
  const nowSeconds = Math.floor(nowMilliseconds / 1000);
  const inputTime = new Date(seconds);
  const inputSeconds = Math.floor(inputTime.getTime() / 1000);
  const elapsedSeconds = nowSeconds - inputSeconds;
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0);
  const todayStartSeconds = Math.floor(todayStart.getTime() / 1000);

  if (elapsedSeconds < 60) {
    return "Just now";
  }

  const intervalMinutes = Math.floor(elapsedSeconds / 60);
  if (intervalMinutes < 60) {
    if (intervalMinutes === 1) return "1 minute ago";
    return intervalMinutes + " minutes ago";
  }

  const intervalHours = Math.floor(elapsedSeconds / 3600);
  if (intervalHours < 24 && inputSeconds >= todayStartSeconds) {
    return "Today " + moment(inputTime).format("hh:mm A");
  }

  return moment(inputTime).format("DD MMM YYYY, hh:mm A");
}


function epocToPrettyDateTime(timestamp) {
  const date = new Date(timestamp);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}

function generateScript(url) {
  return `<script>
  document.addEventListener("DOMContentLoaded", function () {
    var image = document.createElement("img");
    var chatIcon =
      "https://d3tfuasmf2hsy5.cloudfront.net/assets/chatwidget.png";
    var closeIcon =
      "https://d3tfuasmf2hsy5.cloudfront.net/assets/closewidgget.png";
    image.src = chatIcon;
    image.style.width = "80px";
    image.style.cursor = "pointer";
    image.style.borderRadius = "100%";
    image.style.position = "fixed";
    image.style.bottom = "20px";
    image.style.right = "20px";
    image.style.zIndex = "1000";

    var iframe = document.createElement("iframe");
    iframe.src =
      "${url}"
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.border = "1px solid #DEDEDE";
    iframe.style.borderRadius = "16px";
    iframe.style.display = "none";
    iframe.style.zIndex = "1000";

    function toggleIframe() {
      if (iframe.style.display === "none") {
        iframe.style.display = "block";
        image.src = closeIcon;
      } else {
        iframe.style.display = "none";
        image.src = chatIcon;
      }
    }

    image.addEventListener("click", toggleIframe);

    var container = document.createElement("div");
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    container.style.position = "fixed";
    container.style.height = "600px";
    container.style.bottom = "100px";
    container.style.zIndex = "1000";

    if (viewportWidth < 450) {
      container.style.width = "100%";
      container.style.paddingRight = "10px";
      container.style.paddingLeft = "10px";
    } else {
      container.style.right = "20px";
      container.style.width = "450px";
    }

    container.appendChild(image);
    container.appendChild(iframe);

    document.body.appendChild(container);
  });
</script>`;
}

function encodeText(text) {
  return btoa(text);
}

function createWidgetScript(obj) {
  const appHost = window.location.host;
  const serviceHost = new URL(process.env.REACT_APP_CONTENT_MICROSERVICE_URL)
    .host;
  const zbrainApp = encodeText(`${appHost}&&${serviceHost}`);
  return `<script type="text/javascript">
  window.ZBRAIN_APP = "${zbrainApp}";
  window.ZBRAIN_TENANT_ID = "${obj.tenantId}";
  window.ZBRAIN_WIDGET_ID = "${obj.appId}";
  window.QUERY_STRING_PARAMETERS = "";
  (function () {
    d = document;
    s = d.createElement("script");
    s.src = "${window.location.origin}/script.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
</script>`;
}

function formatDate(timestamp) {
  const date = new Date(timestamp * 1000);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year}, ${hours}:${minutes}`;
}

function formatSessionTime(timestamp) {
  const dateObj = new Date(timestamp);

  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const year = String(dateObj.getFullYear()).slice(-2);
  const formattedDate = `${day}/${month}/${year}`;

  const formattedTime = dateObj.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return `${formattedDate} ${formattedTime}`;
}

function objectsAreEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

function shortenString(value, amountL, amountR, stars) {
  return `${value.slice(0, amountL)}${".".repeat(stars)}${value.slice(
    -amountR
  )}`;
}

function shortenHelper(value, amountL, amountR, stars) {
  if (typeof value === "string" && value.length > amountL + amountR) {
    return shortenString(value, amountL, amountR, stars);
  }
  return value;
}
function companyNameShortner(value, amountL = 17, amountR = 12, stars = 2) {
  return shortenHelper(value, amountL, amountR, stars);
}

function shorten(value, amountL = 14, amountR = 12, stars = 4) {
  return shortenHelper(value, amountL, amountR, stars);
}

function emailShortner(value, amountL = 17, amountR = 18, stars = 3) {
  return shortenHelper(value, amountL, amountR, stars);
}

function emailShorten(value, amountL = 10, amountR = 11, stars = 3) {
  return shortenHelper(value, amountL, amountR, stars);
}
function tokenShorten(value, amountL = 35, amountR = 10, stars = 3) {
  return shortenHelper(value, amountL, amountR, stars);
}

function formatDateTime(timestamp) {
  const date = new Date(timestamp);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  return `${day} ${month} ${year}, ${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;
}

function formatDuration(milliseconds) {
  const totalSeconds = milliseconds / 1000;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;
  let formatted = "";

  if (hours > 0) formatted += `${hours}h `;
  if (minutes > 0) formatted += `${minutes}m `;
  
  if (hours === 0 && minutes === 0) {
    if (milliseconds < 1000) {
      formatted += `${Math.floor(milliseconds)}ms`;
    } else {
      formatted += `${remainingSeconds.toFixed(2)}s`;
    }
  } else if (remainingSeconds > 0) {
    formatted += `${remainingSeconds.toFixed(0)}s`;
  }

  return formatted.trim();
}
function convertDecimal(value) {
  if (value % 1 === 0) {
    return value;
  } else {
    return parseFloat(value.toFixed(2));
  }
}

function formatCreditsDecimal(credits) {
  if (Math.abs(credits - Math.round(credits)) > 0.5e-5) {
    return credits.toFixed(5).replace(/\.?0+$/, "");
  } else {
    return credits;
  }
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatNumber(number) {
  if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, "") + "k";
  }
  return number;
}

function isUnlimited(val) {
  return typeof val === "string" && val.toLowerCase() === "unlimited";
}
function convertEpochToDateTime(epoch) {
  // Create a new Date object with the epoch time
  var date = new Date(parseInt(epoch));

  // Extract the components of the date
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var month = date.toLocaleString("en-us", { month: "long" });
  var day = date.getDate();
  var year = date.getFullYear();
  var period = hours >= 12 ? "PM" : "AM";

  // Adjust hours to 12-hour format
  if (hours > 12) {
    hours -= 12;
  }

  // Add leading zeros to minutes if necessary
  minutes = (minutes < 10 ? "0" : "") + minutes;

  // Construct the formatted date string
  var formattedDate =
    hours +
    ":" +
    minutes +
    " " +
    period +
    ", " +
    day +
    " " +
    month +
    " " +
    year;

  return formattedDate;
}

function formatDateWithAmPm(dateString) {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${formattedHours}:${formattedMinutes}${ampm} ${day}-${month}-${year}`;
}

function convertSecondsToReadableTime(timeInSeconds) {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
}

function formatDateHoursMinutes(timestamp) {
  const date = new Date(timestamp);
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

function capitalizeWords(str) {
  if (!str) return "";
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function truncateText(text, limit) {
  return text.length > limit ? text.substring(0, limit) + "..." : text;
}

function getStatusAttributes(status) {
  const statusMap = {
    COMPLETED: {
      color: "bg-green-11953E",
      tooltip: "Completed",
    },
    "IN-QUEUE": {
      color: "bg-orange-10",
      tooltip: "Processing",
    },
    "IN-PROGRESS": {
      color: "bg-orange-10",
      tooltip: "Processing",
    },
    PENDING: {
      color: "bg-orange-10",
      tooltip: "Pending",
    },
    ERRORED: {
      color: "bg-red-FF2116",
      tooltip: "Failed",
    },
  };

  return (
    statusMap[status] || {
      color: "bg-gray-10",
      tooltip: "Unknown Status",
    }
  );
}
function convertToMarkup(content) {
  content = content.replace(
    /^# (.+)$/gm,
    '<h1 style="font-size: 32px; font-weight: semibold; font-style: Poppins;">$1</h1>'
  );
  content = content.replace(
    /^## (.+)$/gm,
    '<h2 style="font-size: 32px; font-weight: semibold;">$1</h2>'
  );
  content = content.replace(
    /^### (.+)$/gm,
    '<h3 style="font-size: 32px; font-weight: semibold;">$1</h3>'
  );
  content = content.replace(
    /^#### (.+)$/gm,
    '<h4 style="font-size: 30px; font-weight: semibold;">$1</h4>'
  );

  content = content.replace(
    /\*(.*?)\*/g,
    '<strong style="font-weight: bold;">$1</strong>'
  );

  content = content.replace(/_(.*?)_/g, "<em>$1</em>");

  content = content.replace(
    /^(?!<h[1-4]|\*|_|<hr>|\s*---)(.+)$/gm,
    '<p style="font-size: 17px;">$1</p>'
  );

  content = content.replace(/^---$/gm, "<hr>");

  content = content.replace(/\s*<p>\s*<\/p>\s*/g, ""); // Remove empty <p> tags
  content = content.replace(/\s*(<p>.*?<\/p>)\s*/g, "$1"); // Trim spaces around <p> tags

  const style = "<style>p { margin-bottom: 1.5em; }</style>";
  return style + content;
}

function getFileNameWithoutExtension(fileName) {
  if (!fileName) return "";

  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) return fileName;

  return fileName.substring(0, lastDotIndex);
}

function getConnectionIcons(name) {
  const connection = connectionsMap.find((conn) =>
    name.toLowerCase().includes(conn.name.toLowerCase())
  );

  return connection ? connection.icon : "/images/zbrain-icon.svg";
}

function getFileType(url) {
  if (!url) {
    console.error("Invalid URL provided");
    return null;
  }

  const cleanUrl = url.split("?")[0];

  const extension = cleanUrl.split(".").pop().toLowerCase();

  const validExtensions = [
    "pdf",
    "csv",
    "docx",
    "xlsx",
    "txt",
    "pptx",
    "json",
  ];

  if (validExtensions.includes(extension)) {
    return extension;
  }
  return null;
};

function initializeStateWithDefaults (configurations, defaultConfigFields) {
  return (
    configurations ||
    (defaultConfigFields
      ? Object.keys(defaultConfigFields).reduce((acc, key) => {
          acc[key] = defaultConfigFields[key].defaultValue || "";
          return acc;
        }, {})
      : {})
  );
};
