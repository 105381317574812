import { keyTypeConstants } from "../constants";
import Utils from "../utility";
import { sessionManager } from "../managers/sessionManager";

const { INVITED_USER, AUTH0_ID_TOKEN, INVITATION_DATA } = keyTypeConstants;

const getToken = () => sessionManager.getDataFromCookies(AUTH0_ID_TOKEN);

const storeData = (data) => {
  Utils.storeToLocalStorage(INVITED_USER, data);
};

const clearData = () => {
  localStorage.removeItem(INVITED_USER);
  localStorage.removeItem(INVITATION_DATA);
};

const token = getToken();

const getUserData = () => {
  if (token) return Utils.getDataFromLocalStorage(INVITED_USER);
  localStorage.clear();
  return null;
};

const data = getUserData();
const userData = !!data ? data : {};

const initialState = {
  isLoggedIn: !!userData?.userId,
  userId: userData?.userId || "",
  name: userData?.name || "",
  firstName: userData?.firstName || "",
  lastName: userData?.lastName || "",
  email: userData?.email || "",
  profilePicture: userData?.profilePicture || "",
  tenantId: userData?.tenantId || "",
  invitationTokenId: userData?.invitationTokenId || "",
  appId: userData?.appId || "",
};

export default function invitedUser(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_INVITED_USER":
      const { isLoggedIn, id_token, ...localData } = {
        ...state,
        ...action.payload,
      };
      storeData(localData);
      return { ...state, ...action.payload, isLoggedIn: true };
    case "REMOVE_INVITED_USER":
      clearData();
      return {
        isLoggedIn: false,
        userId: "",
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        profilePicture: "",
        tenantId: "",
        invitationTokenId: "",
        appId: "",
      };
    default:
      return state;
  }
}
