import { appInstructions } from "../constants";

let initialState = {
  botInstructions: appInstructions,
  defaultSetting: {
    model: "gpt-4o",
    temp: "0.7",
    scoreThreshold: "",
    contextLength: "",
    contextMaxToken: "6000",
    responseMaxToken: "1500",
    isChangeInAgent: false,
    followupConversation: false,
    chunkingStrategy: {
      previousSession: {
        enabled: false,
        tokens: "",
      },
      staticText: {
        enabled: false,
        text: "",
      },
    },
    isFollowUpEnabled: false,
    isSummarizeAgentEnabled: false,
    isCSVAgentEnabled: false,
    isSourceEnabled: false,
  },
};

export default function ConfigureBotComponent(state = initialState, action) {
  switch (action.type) {
    case "SET_BOT_INSTRUCTIONS":
      return {
        ...state,
        botInstructions: action.payload,
      };
    case "SET_DEFAULT_SETTING":
      return {
        ...state,
        defaultSetting: {
          ...state.defaultSetting,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
