let initialState = {
  isSideBarOpen: false,
};
export const checkIsSidebarOpen = (data) => {
  return {
    type: "sidebar",
    payload: data,
  };
};

export default function sidebarComponent(state = initialState, action) {
  switch (action.type) {
    case "sidebar":
      return {
        ...state,
        isSideBarOpen: action.payload,
      };
    case "anotherAction":
      return state;
    case "yetAnotherAction":
      return state;
    default:
      return state;
  }
}

