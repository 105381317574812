import React from "react";

function LoadingScreen({ height = "min-h-screen" }) {
  return (
    <div
      className={"grid place-content-center w-full bg-transparent " + height}
    >
      <div className="loader-5 center">
        <span></span>
      </div>
    </div>
  );
}

export default LoadingScreen;
