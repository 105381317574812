let initialState = {
    ids: [],
  };
  export const setFileUploadResults = (results) => {
    return {
      type: "SET_FILE_UPLOAD_RESULTS",
      payload: results,
    };
  };
  export default function KBImportsComponent(state = initialState, action) {
    switch (action.type) {
      case "SET_FILE_UPLOAD_RESULTS":
        return {
          ...state,
          ids: action.payload,
        };
      default:
        return state;
    }
  }