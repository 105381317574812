let initialState = {
  isVerified: true,
  isPlanUpgraded: true,
};
export const verifiedEmailOpen = (data) => {
  return {
    type: "verified",
    payload: data,
  };
};
export const isUpgradePlanOpen = (data) => {
  return {
    type: "upgradePlan",
    payload: data,
  };
};

export default function verifiedEmailComponent(state = initialState, action) {
  switch (action.type) {
    case "verified":
      return {
        ...state,
        isVerified: action.payload,
      };
    case "upgradePlan":
      return {
        ...state,
        isPlanUpgraded: action.payload,
      };
    case "yetAnotherAction":
      return state;
    default:
      return state;
  }
}
