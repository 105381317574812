import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
import { history } from "../managers/history";

const { AUTH0_ID_TOKEN, CRM_CODE } = keyTypeConstants;

const {
  METHOD_TYPE: { GET, POST, PATCH },
  API_END_POINT: { HUBSPOT_TENANT },
} = httpConstants;

export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export default class HubspotService {
  baseUrl = process.env.REACT_APP_CONTENT_MICROSERVICE_URL;

  createTenant = async (data) => {
    return new Promise((resolve, reject) => {
      httpService(POST, `${this.baseUrl}${HUBSPOT_TENANT}`, data)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  updateConfig = async (data) => {
    const url = `${this.baseUrl}${HUBSPOT_TENANT}`;
    const res = await httpService(PATCH, url, data, getHeaders());
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  hubspotTenant = async (_code, _tenantId) => {
    try {
      const response = await this.createTenant({
        code: _code,
        tenantId: _tenantId,
      });
      localStorage.setItem(
        "HUBSPOT_USER",
        JSON.stringify(response?.data?.hubId)
      );
      if (!response?.isSubscribed) {
        history.push("/hubspot/pricing");
      } else {
        history.push("/hubspot/sales-enablement-tool");
      }
      return true;
    } catch (error) {
      console.log(error);
    } finally {
      sessionManager.removeDataFromCookies(CRM_CODE);
    }
    return false;
  };

  getHubspotDetail = async (tenantId) => {
    const hubId = JSON.parse(localStorage.getItem("HUBSPOT_USER"));
    const query = `?tenantId=${tenantId}&hubId=${hubId}`;

    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + HUBSPOT_TENANT + query, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
}
