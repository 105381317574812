import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

const {
  METHOD_TYPE: { GET, DELETE },
  API_END_POINT: { FLOW, FLOWS, QUERY_FLOW },
} = httpConstants;
const { AUTH0_ID_TOKEN } = keyTypeConstants;

export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export default class FlowService {
  baseUrl = process.env.REACT_APP_CONTENT_MICROSERVICE_URL;

  getFlows = async (queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + FLOWS + "?" + query, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  deleteFlow = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(DELETE, this.baseUrl + FLOW, requestData, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  queryFlow = async (data) => {
    const query = `?${new URLSearchParams(data).toString()}`;
    const res = await httpService(
      GET,
      this.baseUrl + QUERY_FLOW + query,
      null,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };
}
