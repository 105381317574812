import { httpConstants } from "../constants";

const createRequestOptions = (method, data, headers) => {
  const { GET } = httpConstants.METHOD_TYPE;
  const requestOptions = {
    method: method,
    headers: headers || { "Content-Type": "application/json" },
  };

  if (method !== GET) requestOptions.body = JSON.stringify(data);
  return requestOptions;
};

/**
 * Sends an HTTP request to the specified URL using the provided method, data, and headers.
 *
 * @param {string} method - The HTTP method to use for the request (e.g. 'GET', 'POST', 'PUT', 'DELETE').
 * @param {string} url - The URL to send the request to.
 * @param {any} data - The data to include in the request body (for non-GET requests).
 * @param {Object} headers - The headers to include in the request.
 * @returns {Promise<any>} - A Promise that resolves to the response data, or rejects with an error.
 */
export const httpService = (method, url, data, headers) => {
  const requestOptions = createRequestOptions(method, data, headers);
  return fetch(url, requestOptions)
    .then(function handleResponse(response) {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);

        if (!data.success) {
          const error =
            data.responseCode === 404
              ? data
              : (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        return data;
      });
    })
    .catch(function (err) {
      return err;
    });
};
