import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";

const loggerMiddleware = createLogger();
const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === "development") middlewares.push(loggerMiddleware);

export default createStore(rootReducer, applyMiddleware(...middlewares));
