import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
const { AUTH0_ID_TOKEN } = keyTypeConstants;
export function getHeaders() {
  return {
    "Content-Type": httpConstants.CONTENT_TYPE.APPLICATION_JSON,
    skip: true,
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export default class KnowledgeBaseService {
  baseUrl = process.env.REACT_APP_CONTENT_MICROSERVICE_URL;
  localUrl = "http://localhost:3010"
  deleteKnowledgeBaseById = async (id) => {
    let url =
      process.env.REACT_APP_CONTENT_MICROSERVICE_URL +
      httpConstants.API_END_POINT.KNOWLEDGE_BASE +
      "/" +
      id;
    return new Promise((resolve, reject) => {
      httpService(httpConstants.METHOD_TYPE.DELETE, url, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  deleteKnowledgeBaseImportById = async (requestData) => {
    let url =
      process.env.REACT_APP_CONTENT_MICROSERVICE_URL +
      httpConstants.API_END_POINT.KNOWLEDGE_BASE_IMPORT;
    return new Promise((resolve, reject) => {
      httpService(
        httpConstants.METHOD_TYPE.DELETE,
        url,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  createknowledgeBase = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        httpConstants.METHOD_TYPE.POST,
        this.baseUrl + "/knowledge-base",
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  createSchema = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        httpConstants.METHOD_TYPE.POST,
        this.baseUrl + "/knowledge-base-data-structure",
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  deleteknowledgeBase = async (id, queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        httpConstants.METHOD_TYPE.DELETE,
        this.baseUrl + "/knowledge-base/" + id + "?" + query,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  updateknowledgeBase = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        httpConstants.METHOD_TYPE.PUT,
        this.baseUrl + "/knowledge-base",
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

}


