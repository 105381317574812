import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
const { AUTH0_ID_TOKEN, APP_USER_AUTH0_ID_TOKEN } = keyTypeConstants;

export function getHeaders() {
  return {
    "Content-Type": httpConstants.CONTENT_TYPE.APPLICATION_JSON,
    skip: true,
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}


export function getAppUsersHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      APP_USER_AUTH0_ID_TOKEN
    )}`,
  };
}

export default class PrivateLinkService {
  baseUrl = process.env.REACT_APP_CONTENT_MICROSERVICE_URL;
  tenantBaseUrl = process.env.REACT_APP_TENANT_MICROSERVICE_URL;

  getAppDetailsById = async ({ appId, tenantId }) => {
    let url =
      process.env.REACT_APP_CONTENT_MICROSERVICE_URL +
      httpConstants.API_END_POINT.MANAGE_APPS +
      "/" +
      appId +
      `?tenantId=${tenantId}`;
    return new Promise((resolve, reject) => {
      httpService(httpConstants.METHOD_TYPE.GET, url, {}, getAppUsersHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getConversationListById = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    let url =
      process.env.REACT_APP_CONTENT_MICROSERVICE_URL +
      httpConstants.API_END_POINT.CONVERSATIONS +
      "?" +
      query;
    return new Promise((resolve, reject) => {
      httpService(httpConstants.METHOD_TYPE.GET, url, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  conversationChatbot = async (data) => {
    const url = `${process.env.REACT_APP_CONTENT_MICROSERVICE_URL}${httpConstants.API_END_POINT.QUERY} `;
    return new Promise((resolve, reject) => {
      httpService(httpConstants.METHOD_TYPE.POST, url, data, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  clearAllConversation = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    let url =
      process.env.REACT_APP_CONTENT_MICROSERVICE_URL +
      httpConstants.API_END_POINT.CLEAR_CONVERSATIONS +
      "?" +
      query;
    return new Promise((resolve, reject) => {
      httpService(httpConstants.METHOD_TYPE.PUT, url, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getConversationById = async (id, queryData) => {
    const query = new URLSearchParams(queryData).toString();
    let url =
      process.env.REACT_APP_CONTENT_MICROSERVICE_URL +
      httpConstants.API_END_POINT.USER_CHAT +
      "/" +
      id +
      "?" +
      query;
    return new Promise((resolve, reject) => {
      httpService(httpConstants.METHOD_TYPE.GET, url, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  deleteConversationById = async (id, queryData) => {
    const query = new URLSearchParams(queryData).toString();
    let url =
      process.env.REACT_APP_CONTENT_MICROSERVICE_URL +
      httpConstants.API_END_POINT.DELETE_CONVERSATION +
      "/" +
      id +
      "?" +
      query;
    return new Promise((resolve, reject) => {
      httpService(httpConstants.METHOD_TYPE.PUT, url, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  invitationToChat = async (data) => {
    const url = `${process.env.REACT_APP_TENANT_MICROSERVICE_URL}${httpConstants.API_END_POINT.VERIFY_INVITE} `;
    return new Promise((resolve, reject) => {
      httpService(httpConstants.METHOD_TYPE.POST, url, data, getAppUsersHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  signUp = async (requestData) => {
    let url =
      process.env.REACT_APP_TENANT_MICROSERVICE_URL +
      httpConstants.API_END_POINT.SIGN_UP_USER;
    return new Promise((resolve, reject) => {
      httpService(
        httpConstants.METHOD_TYPE.POST,
        url,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  signIn = async (requestData) => {
    let url =
      process.env.REACT_APP_TENANT_MICROSERVICE_URL +
      httpConstants.API_END_POINT.SIGN_IN_USER;
    return new Promise((resolve, reject) => {
      httpService(
        httpConstants.METHOD_TYPE.POST,
        url,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getUserProfile = async (email, userId, tenantId) => {
    let query = `?email=${email}&tenantId=${tenantId}`;
    if (userId.startsWith("google-oauth2")) {
      query += `&connection=google-oauth2`;
    }
    const res = await httpService(
      httpConstants.METHOD_TYPE.GET,
      this.tenantBaseUrl +
        httpConstants.API_END_POINT.AUTH_PROFILE_BY_EMAIL +
        query,
      null,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };
}
